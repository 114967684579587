<link rel="shortcut icon" type="image/x-icon" href="/assets/images/favicon.ico">
<!-- header section -->
<header class="row headerSection pull_left">
    <div class="wrap">
        <div class="menuIcon"></div>
        <a routerLink="/" class="logo-panel"><img src="/assets/images/igv-logo.jpg" /></a>
        <div class="pull-right">
        <nav class="navigation-panel">
            <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><i class="fa fa-home"></i> Home</a>
            <a routerLink="{{env.frontPath}}about-us" routerLinkActive="active"><i class="fa fa-info-circle"></i> About Us</a>
            <a routerLink="{{env.frontPath}}track-application" routerLinkActive="active"><i class="fa fa-search"></i> Track</a>
            <!--<a href="#"><i class="fa fa-handshake-o"></i> Become our Partner</a>-->
            <a routerLink="{{env.frontPath}}contact-us" routerLinkActive="active"><i class="fa fa-address-book"></i> Contact Us</a>
            <!-- shakti
				    <a *ngIf="isLoggedIn; then afterLogin else beforeLogin"></a>
            <ng-template #beforeLogin>
                <a routerLink="{{env.customerPath}}login" routerLinkActive="active"><i class="fa fa-user"></i> Register / Login</a>
            </ng-template> routerLink="{{env.customerPath}}dashboard"-->
            <ul class="naviMenu" *ngIf="isLoggedIn===false && isCorporateLoggedIn===false">
              <li><a routerLinkActive="active"><i class="fa fa-user"></i> Login</a>
                <ul>
                  <li><a *ngIf="isCorporateLoggedIn===false" routerLink="{{env.customerPath}}login" routerLinkActive="active"><i class="fa fa-user"></i> Register / Login</a></li>
                  <li><a *ngIf="isLoggedIn===false" routerLink="{{env.corporatePath}}login" routerLinkActive="active"><i class="fa fa-user"></i> Corporate Login</a></li>
                </ul>
		          </li>
		        </ul>
            <ul class="naviMenu" *ngIf="isLoggedIn===true">
              <li><a routerLink="{{env.customerPath}}dashboard" routerLinkActive="active"><i class="fa fa-user"></i> My Account <!-- <i *ngIf="(profileComplete|async)!=100"  class="fa fa-exclamation-circle" style="font-size:20px;color:red"></i> --></a>
                <ul>
                  <li><a routerLink="{{env.customerPath}}dashboard"><i class="fa fa-dashboard yellow paddingRight"></i> Dashboard</a></li>
                  <li><a href="javascript:void(0)"><i class="fa fa-key yellow paddingRight"></i> Rewardz Points: <b class="r_p">{{walletAmount}}</b> <!-- <span class="addMoney"><i class="fa fa-plus"></i> Add Money</span> --></a></li>
                  <li><a routerLink="{{env.customerPath}}inbox"><i class="fa fa-envelope yellow paddingRight"></i> Inbox</a></li>
                  <li><a routerLink="/"><i class="fa fa-file yellow paddingRight"></i> Apply Visa</a></li>
                  <li><a routerLink="{{env.customerPath}}visa-history"><i class="fa fa-eye yellow paddingRight"></i> View/Applied for Visa</a></li>
                  <li><a routerLink="{{env.customerPath}}add-family-member"><i class="fa fa-users yellow paddingRight"></i> Add/View Traveller </a></li>
                  <li><a routerLink="{{env.customerPath}}edit-profile"><i class="fa fa-edit yellow paddingRight"></i> Edit Profile</a></li>
                  <li><a routerLink="{{env.customerPath}}change-password"><i class="fa fa-key yellow paddingRight"></i> Change Password</a></li>
                  <li><a (click)="logout();" href="javascript:void();"><i class="fa fa-power-off yellow paddingRight"></i> Logout</a></li>
                </ul>
              </li>
            </ul>
            <ul class="naviMenu" *ngIf="isCorporateLoggedIn===true">
              <li><a routerLink="{{env.corporatePath}}dashboard" routerLinkActive="active"><i class="fa fa-user"></i> My Account</a>
                <ul>
                  <li><a routerLink="{{env.corporatePath}}dashboard"><i class="fa fa-dashboard yellow paddingRight"></i> Dashboard</a></li>
                  <!-- <li><a routerLink="{{env.frontPath}}add-money"><i class="fa fa-key yellow paddingRight"></i> Rewardz Points: {{walletAmount}}</a></li> -->
                  <li><a href="javascript:void(0)"><i class="fa fa-key yellow paddingRight"></i> Rewardz Points: {{walletAmount}}</a></li>
                  <li><a href="javascript:void();"><i class="fa fa-key yellow paddingRight"></i> Credit Balance: {{creditAmount}}</a></li>
                  <li><a routerLink="{{env.corporatePath}}view-employee"><i class="fa fa-users yellow paddingRight"></i> Add/View Employee</a></li>
                  <li><a routerLink="{{env.corporatePath}}view-employee"><i class="fa fa-file yellow paddingRight"></i> Apply Visa</a></li>
                  <li><a routerLink="{{env.corporatePath}}visa-history"><i class="fa fa-eye yellow paddingRight"></i> View/Applied for Visa</a></li>
                  <li><a routerLink="{{env.corporatePath}}relationshipmanager"><i class="fa fa-users yellow paddingRight"></i> Relationship Manager</a></li>
                  <li><a routerLink="{{env.corporatePath}}edit-profile"><i class="fa fa-edit yellow paddingRight"></i> Complete Profile</a></li>
                  <li><a routerLink="{{env.corporatePath}}change-password"><i class="fa fa-key yellow paddingRight"></i> Change Password</a></li>
                  <li><a (click)="logout();" href="javascript:void();"><i class="fa fa-power-off yellow paddingRight"></i> Logout</a></li>
                </ul>
              </li>
            </ul>
        </nav>
        <div class="mobWhatsappIcon">
            <div class="row">
			        <a *ngIf="corporateLogo" routerLink="/" class="logo-panel"><img src="/uploads/filemanager/catalog/{{corporateLogo}}" /></a>

			        <a *ngIf="corporateLogo===''" href="https://api.whatsapp.com/send?phone=971505863986" target="_blank" class="container chat"><img src="/assets/images/whatsapp-chat1.jpg" title="Whatsapp Icon" alt="Whatsapp Icon"></a></div>
            <!--<div class="row alignCenter mobfont11">+971-505863986</div>-->
            </div>
        </div>
    	</div>
</header>
<!-- /header section -->
<div class="alertBoxToaser success displayNone">
<i class="fa fa-info-circle"></i>&nbsp;&nbsp; <span class="alertBoxToaserText floatNone"></span>
</div>