<!-- body Panel -->
<div class="row bodypanel marginTop80">
    <div class="wrap gapping">
        <div class="row">
            <div class="container col-sm-8">
                <div class="row paddingInBoxExtra alignCenter">
                <div class="container col-sm-8"><img src="{{ cdnUrl }}/assets/images/page-not-found.png" alt="" title="" width="250" height="250"></div>
                    <h3 class="row paddingBottom orange font36 borderBottom fontweightNone titleFont">Oops ! Page not found.</h3>
                    <div class="row paddingInBoxExtra paddingBottomNone">
                        <p class="row">We can not find the page you are looking for</p>
                        <p class="row marginBottom">Its look like you have taken wrong turn. Here are few links to get back on track:</p>
                        <div class="container paddingTop">
                        <a href="/" class="btn-lg custom1-btn marginRight"><i class="fa fa-home"></i> Home Page</a>
                        <a href="{{env.frontPath}}contact-us" class="btn-lg custom1-btn"><i class="fa fa-address-book"></i> Contact Us</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /body Panel -->