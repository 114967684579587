import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';


@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
	
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      setHeaders: {
        'Content-Type' : 'application/json; charset=utf-8',
        'Accept'       : 'application/json',
        'Authorizations': '222cGhwX2NsaWVudDp4VGtMUzFhWkpQRUhqemc1ODlYSlF6N1dCQWtNUmkxUVRQMnJyYzk1Y0pVcmNMdHVESGszS29qT2xlYlZuMlFQ',
		
		'domainId':"1",
		'lang':"en",
		'referrerUrl': document.referrer,
		'pathUrl':window.location.href
		      
	},
    });

    return next.handle(req);
  }
}