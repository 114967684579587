import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { User } from '../_model/user';
import { Country } from '../_model/country';

@Injectable({ providedIn: 'root' })
export class AuthencticateService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    public myProfileVar = new BehaviorSubject<any>("");
    public ispopUpShow = new BehaviorSubject<boolean>(true);  
		

    constructor(
        private http: HttpClient,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.currentUserSubject = new BehaviorSubject<any>(localStorage.getItem('currentUser'));
        this.currentUser = this.currentUserSubject.asObservable();
        
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    setMyGV(val : string){
        this.myProfileVar.next(val);
    }

    getMyGV(){
        return this.myProfileVar.asObservable();
    }  

    setMyPopVar(val : boolean){
        this.ispopUpShow.next(val);
    }

    getMyPopVar(){
        return this.ispopUpShow.asObservable();
    }

    /* shakti
		login(email_id: string, password: string) {
        return this.http.post<any>(`${environment.apiUrl}/customer/userLogin`, { email_id, password })
        .pipe(map(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if(user.Code!=204)
            {
                sessionStorage.setItem('currentUser', JSON.stringify(user));
				localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);
            }
           
            return user;
        }));
    } */
	
	
	login(email_id: string, password: string, user_type: any) {
        return this.http.post<any>(`${environment.apiUrl}/customer/userLogin`, { email_id, password,user_type })
        .pipe(map(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if(user.Code!=204)
            {
			/* console.log(user);
			return false; */
                if(user.Data.user_type=='Customer')
				{
					//sessionStorage.setItem('currentUser', JSON.stringify(user));
					localStorage.setItem('currentUser', JSON.stringify(user));
				}
				if(user.Data.user_type=='Corporate')
				{
					sessionStorage.setItem('corporateUser', JSON.stringify(user));
					localStorage.setItem('corporateUser', JSON.stringify(user));
					
				}
					this.currentUserSubject.next(user);
            }
           
            return user;
        }));
    }
	
	

    autologin(email_id: string, password: string) {
        return this.http.post<any>(`${environment.apiUrl}/customer/userAutoLogin`, { email_id, password })
        .pipe(map(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if(user.Code!=204)
            {
                sessionStorage.setItem('currentUser', JSON.stringify(user));
				localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);
            }
           
            return user;
        }));
    }

    socialLogin(email_id: string) {
        return this.http.post<any>(`${environment.apiUrl}/customer/socialLogin`, { email_id })
        .pipe(map(user => {
			sessionStorage.setItem('currentUser', JSON.stringify(user));
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserSubject.next(user);
            return user;
        }));
    }

    forgotPassword(email_id: string) {
        return this.http.post<any>(`${environment.apiUrl}/customer/forgotPassword`, { email_id })
        .pipe(map(user => {
            return user;
        }));
    }

    resetPassword(password: string, email_id:any) {
        return this.http.post<any>(`${environment.apiUrl}/customer/resetPassword`, { password,email_id})
        .pipe(map(user => {
            this.currentUserSubject.next(user);
            return user;
        }));
    }

    changePassword(current_password: string, password: string, confirm_password: string, id:any) {
        return this.http.post<any>(`${environment.apiUrl}/customer/changePassword`, { current_password, password, confirm_password,id})
        .pipe(map(user => {
            this.currentUserSubject.next(user);
            return user;
        }));
    }

    getDestination(){
	
	var Url="/customer/destination";
	if (localStorage.getItem('currentUser')) {
      var currentUser = JSON.parse(localStorage.getItem('currentUser')).Data.customer_id;
	  var Url=Url+"/0/"+JSON.parse(localStorage.getItem('currentUser')).Data.customer_id;
    }
	
	if (localStorage.getItem('corporateUser')) {
      var currentUser = JSON.parse(localStorage.getItem('corporateUser')).Data.customer_id;
	  var Url=Url+"/0/"+JSON.parse(localStorage.getItem('corporateUser')).Data.customer_id;
    }
	
		return this.http.get<Country[]>(`${environment.apiUrl}`+Url);
		
		
        return this.http.get<Country[]>(`${environment.apiUrl}/customer/destination`);
    }
	
	getMoreDestination() {
        return this.http.post<any>(`${environment.apiUrl}/customer/moreDestination`, {})
        .pipe(map(moreDestination => {
            this.currentUserSubject.next(moreDestination);
            return moreDestination;
        }));
    }
	
    getRelationship(){
        return this.http.get<Country[]>(`${environment.apiUrl}/customer/relationship`);
    }
	
	getStaticData(){
        return this.http.get<Country[]>(`${environment.apiUrl}/customer/staticData`);
    }
	
	
	getDestinationName(id:any,destinationId:any) {
        return this.http.post<any>(`${environment.apiUrl}/customer/destinationById`, { id,destinationId })
        .pipe(map(user => {
            this.currentUserSubject.next(user);
            return user;
        }));
    }

    /*getDestinationDetails_old(destination: any, nationality: any, livingin:any){
        return new Promise(async (resolve, reject) => {
          return this.http.post(`${environment.apiUrl}/customer/destinationByName`,{destination,nationality,livingin}).subscribe(
            (data) => {
              resolve(data)
            },
            (err) => {
              reject(err);
            }
          );
        });
      }*/
	
	getDestinationDetails(destination: any, nationality: any, livingin:any) {
       
      return this.http.post<any>(`${environment.apiUrl}/customer/destinationByName`, {destination,nationality,livingin}).pipe(map(getDestinationDetails => {
          return getDestinationDetails;
      }));
    }

    getNationalityLivingIn(id:any) {
        return this.http.get<any>(`${environment.apiUrl}/customer/destination/${id}`).pipe(map(countryData => {
            return countryData;
        }));
    }
      
    registration(user: User){
        return this.http.post<any>(`${environment.apiUrl}/customer/customerRegistration`, user)
        .pipe(map(user => {
           
            if(user.Message!='error')
            {
                sessionStorage.setItem('currentUser', JSON.stringify(user));
				localStorage.setItem('currentUser', JSON.stringify(user));
            }
            this.currentUserSubject.next(user);
            return user;
        }));
    }

    viewProfile(id:any) {
        return this.http.post<any>(`${environment.apiUrl}/customer/userProfile`, {id}).pipe(map(user => {
            this.currentUserSubject.next(user);
            return user;
        }));
    }
	
	updateProfile(first_name: string, last_name: string, gender: string, passport_no:string, nationality:string,id:any,date_of_birth:string,anniversary_date:string,address:string,city:string,state:string,pincode:string,profilepic:string,passport_expiry_date_day: string,passport_expiry_date_month: string,passport_expiry_date_year: string,livingin_id:any) {
        return this.http.post<any>(`${environment.apiUrl}/customer/updateProfile`, { first_name, last_name, gender,passport_no,nationality,id,date_of_birth,anniversary_date,address,city,state,pincode,profilepic,passport_expiry_date_day,passport_expiry_date_month,passport_expiry_date_year,livingin_id})
        .pipe(map(user => {
            this.currentUserSubject.next(user);
            return user;
        }));
    }

    updateProfileCustomer(first_name: string, last_name: string, gender: string, passport_no:string, nationality:string,id:any,date_of_birth:string,anniversary_date:string,address:string,city:string,state:string,pincode:string,profilepic:string,passport_expiry_date_day: string,passport_expiry_date_month: string,passport_expiry_date_year: string,livingin_id:any,personal_passport_photo:string,country_isd_code:any,contact_no:any) {
        return this.http.post<any>(`${environment.apiUrl}/customer/updateProfile`, { first_name, last_name, gender,passport_no,nationality,id,date_of_birth,anniversary_date,address,city,state,pincode,profilepic,passport_expiry_date_day,passport_expiry_date_month,passport_expiry_date_year,livingin_id,personal_passport_photo,country_isd_code,contact_no})
        .pipe(map(user => {
            this.currentUserSubject.next(user);
            return user;
        }));
    }

    addFamilyMember(first_name: string, email_id: string, last_name: string, passport_no:string, passport_expiry_date:string, nationality:string,id:any,date_of_birth:string,relationship_id:string,contact_no:string,profilepic:string,country_isd_code:string,personal_passport_photo:string) {
        return this.http.post<any>(`${environment.apiUrl}/customer/addFamilyMember`, { first_name,last_name,email_id,passport_no,passport_expiry_date,nationality,id,date_of_birth,relationship_id,contact_no,profilepic,country_isd_code,personal_passport_photo})
        .pipe(map(user => {
            this.currentUserSubject.next(user);
            return user;
        }));
    }

    allFamilyMembers(id:any) {
        return this.http.post<[any]>(`${environment.apiUrl}/customer/getAllFamilyMember`, {id})
        .pipe(map(members => {				
            return members;
        }));
    }
	
	allFamilyMembersForVisa(id:any) {
        return this.http.post<[any]>(`${environment.apiUrl}/customer/allFamilyMembersForVisa`, {id})
        .pipe(map(members => {				
            return members;
        }));
    }

    viewMemberProfile(id:Number) {
        return this.http.post<any>(`${environment.apiUrl}/customer/viewMemberProfile`, {id}).pipe(map(user => {
            this.currentUserSubject.next(user);
            return user;
        }));
    }

    updateMemberProfile(first_name: string, email_id: string,last_name: string, passport_no:string, passport_expiry_date:string, nationality:string,id:any,date_of_birth:string,relationship_id:string,contact_no:string,profilepic:string,country_isd_code:string,personal_passport_photo:string) {
        return this.http.post<any>(`${environment.apiUrl}/customer/updateMemberProfile`, { first_name, last_name,passport_no,passport_expiry_date,nationality,id,date_of_birth,relationship_id,contact_no,profilepic,country_isd_code,personal_passport_photo})
        .pipe(map(user => {
            this.currentUserSubject.next(user);
            return user;
        })); 
    }

    deleteMember(id:any) {
        return this.http.post<any>(`${environment.apiUrl}/customer/deleteMember`, {id})
        .pipe(map(user => {
            this.currentUserSubject.next(user);
            return user;
        }));
    }    

    appliedVisa(customer_id:any) {
        return this.http.post<[any]>(`${environment.apiUrl}/customer/appliedVisa`, {customer_id})
        .pipe(map(allApplications => {				
            return allApplications;
        }));
    }

    viewVisaApplication(customer_id:any,visa_id:any) {
        return this.http.post<[any]>(`${environment.apiUrl}/customer/appliedVisa`, {customer_id,visa_id})
        .pipe(map(viewIndividualApplication => {				
            return viewIndividualApplication;
        }));
    }

    customerDashboard(customer_id:any) {
        return this.http.post<[any]>(`${environment.apiUrl}/customer/dashboard`, {customer_id})
        .pipe(map(viewCustomerDashboard => {				
            return viewCustomerDashboard;
        }));
    }
	
	getDocumentList(applData: any){
		  return this.http.post<any>(`${environment.apiUrl}/customer/viewDocument`, applData).pipe(map(documentnamelist => {
			return documentnamelist;
      }));
    }

	uploadDocument(applData: any){
          return this.http.post<any>(`${environment.apiUrl}/customer/UploadDocument`, applData).pipe(map(uploadeddocument => {
		  return uploadeddocument;
      }));
    }

    uploadCustomerDocument(applData: any){
        return this.http.post<any>(`${environment.apiUrl}/customer/UploadCustomerDocument`, applData).pipe(map(uploadeddocumentcustomer => {
        return uploadeddocumentcustomer;
    }));
  }
	
	//Inbox
	customerInbox(email_id:any,limit:any,offset:any,status_id:any,fromDate:any,toDate:any) {
        return this.http.post<[any]>(`${environment.apiUrl}/customer/inbox`, {email_id,limit,offset,status_id,fromDate,toDate})
        .pipe(map(viewInbox => {				
            return viewInbox;
        }));
    }
	
	customerInboxDetails(id:any,application_id:any) {
        return this.http.post<[any]>(`${environment.apiUrl}/customer/inboxDetails`, {id,application_id})
        .pipe(map(viewInboxDetails => {				
            return viewInboxDetails;
        }));
    }
	
	
	
	customerInboxReply(id:any,application_id:any) {
        return this.http.post<[any]>(`${environment.apiUrl}/customer/inboxDetails`, {id,application_id})
        .pipe(map(viewInboxReply => {				
            return viewInboxReply;
        }));
    }
	
	customerInboxReplyBody(id:any,application_id:any,replybody:any,customer_id:any) {
        return this.http.post<any>(`${environment.apiUrl}/customer/inboxReply`, {id,application_id,replybody,customer_id})
        .pipe(map(viewInboxReplyBody => {
            return viewInboxReplyBody;
        }));
    }
	
	customerInboxReadUnread(id:any) {
        return this.http.post<[any]>(`${environment.apiUrl}/customer/dashboard`, {id})
        .pipe(map(viewInboxReplyReadUnread => {				
            return viewInboxReplyReadUnread;
        }));
    }
	
	
	InboxReadUnreadAll(statusid:any,inboxIds:any) {
        return this.http.post<[any]>(`${environment.apiUrl}/customer/inboxReadUnread`, {statusid,inboxIds})
        .pipe(map(inboxreadunread => {				
            return inboxreadunread;
        }));
    }
	
	InboxAddRemoveStar(statusid:any,inboxIds:any) {
        return this.http.post<[any]>(`${environment.apiUrl}/customer/inboxAddRemoveStar`, {statusid,inboxIds})
        .pipe(map(inboxreadunread => {				
            return inboxreadunread;
        }));
    }
	
	
	getWorldMap() {
        return this.http.post<any>(`${environment.apiUrl}/customer/worldMap`, {})
        .pipe(map(worldMap => {
            return worldMap;
        }));
    }
	
	
}