

<!-- footer Panel -->
<footer class="row footerSection pull_left" id="footerID" >
<div class="wrap gapping" *ngIf="fShow=='footerShow'">



<div class="col-sm-1Third paddingInBoxExtra tabpaddingInBox">
<div class="row titleFont font20 brown marginBottom">Main Links</div>
<div class="row">
<!-- <a routerLink="/" class="links"><i class="fa fa-home"></i> Home</a> -->
<a routerLink="{{env.frontPath}}about-us" class="links"><i class="fa fa-info-circle"></i> About Us</a>
<a routerLink="{{env.frontPath}}track-application" class="links"><i class="fa fa-search"></i> Track</a>
<!--<a href="#" class="links"><i class="fa fa-handshake-o"></i> Mice</a>
<a href="#" class="links"><i class="fa fa-handshake-o"></i> Become a Partner</a>-->
<a routerLink="{{env.frontPath}}contact-us" class="links"><i class="fa fa-address-book"></i> Contact Us</a>
<a routerLink="{{env.frontPath}}terms-of-use" class="links"><i class="fa fa-file"></i> Terms of Use</a>
<a routerLink="{{env.frontPath}}privacy-policy" class="links"><i class="fa fa-user"></i> Privacy Policy</a>
<a routerLink="{{env.frontPath}}blogs" class="links"><i class="fa fa-sticky-note"></i> Blog</a>
<a routerLink="{{env.frontPath}}faqs" class="links"><i class="fa fa-info-circle"></i> FAQ</a>
<a routerLink="{{env.frontPath}}repeat-customer" class="links"><i class="fa fa-info-circle"></i> Repeat Customer</a>
<a routerLink="{{env.frontPath}}app-download" class="links"><i class="fa fa-info-circle"></i> Download App </a>
<!-- <a routerLink="{{env.frontPath}}press-release" class="links"><i class="fa fa-info-circle"></i> Press Release</a> -->
<!--<a routerLink="/video" class="links"><i class="fa fa-info-circle"></i> Videos</a>-->
<!-- <a class="links"><i class="fa fa-binoculars"></i> Tours</a>
<a class="links"><i class="fa fa-building"></i> Hotels</a>
<a class="links"><i class="fa fa-plane"></i> Flights</a>
<a href="#" class="paddingTop marginTop"><img src="/assets/images/repeat-customer.png" height="42"/></a>-->
</div>
</div>

<div class="col-sm-1Third paddingInBoxExtra tabpaddingInBox mobfont12">
<div class="row titleFont font20 brown marginBottom">Contact Us</div>
<div class="row"><i class="fa fa-whatsapp marginRight"></i> <a href="tel:+971505863986" class="grey floatNone">+971-505863986</a></div>
<div class="row grey"><i class="fa fa-phone marginRight"></i> <a href="tel:+97145464650" class="grey floatNone">+971-45464650</a> (10 AM - 7 PM Dubai Time)</div>
<div class="row marginBottom paddingBottom"><i class="fa fa-envelope marginRight"></i> <a href="mailto:contact@instaglobaltravel.com" class="grey floatNone">contact@instaglobaltravel.com</a></div>

<div class="row titleFont font20 brown marginBottom">Our Location</div>
<div class="row"><strong>INSTA TOURISM LLC</strong></div>
<div class="row marginBottom">201, M Square Commercial Building, Near Double Tree Hotel, Bur Dubai, Dubai, UAE, P.O. Box : 120375</div>

<div class="row">
<img src="/assets/images/payment.png" class="pull-left" alt="InstaGobalTravel" title="Card Accepted" width="" height="35">
</div>


</div>

<div class="col-sm-1Third paddingInBoxExtra tabpaddingInBox">
<div class="row">
<div class="soicalLinks-section row">
<div class="titleFont font20 brown marginBottom">Follow Us at:</div>
<div class="soicalLinks">
<a href="https://www.facebook.com/instaglobaltravelcom" class="facebook marginNone" target="_blank"><i class="fa fa-facebook"></i></a>
<a href="https://twitter.com/global__travel" class="twitter" target="_blank"><i class="fa fa-twitter"></i></a>
<a href="https://www.linkedin.com/company/instaglobaltravel" class="linkedin" target="_blank"><i class="fa fa-linkedin"></i></a>
<a href="https://www.youtube.com/channel/UCW7djHkWjjBr5AopYGICAgg" class="youtube" target="_blank"><i class="fa fa-youtube"></i></a>
<a href="https://www.instagram.com/insta_global_travel/" class="instagram" target="_blank"><i class="fa fa-instagram"></i></a>
</div>
</div>

<div class="row applink-section marginTop">
<!--<p class="row">Scan the QR code to download the <br />InstaGlobalTravel app</p>-->

<p class="row font20 fontBold marginBottom">Get started with the mobile app!</p>
<p class="col-sm-8 marginBottom">Get Discounts and Updates via IGT App.</p>
<p class="row">Apply Visa Anytime, Anywhere.</p>
<p class="row marginBottom fontBold">Download Now</p>

<div class="row">
<a href="https://play.google.com/store/apps/details?id=com.insta_global_app&pcampaignid=web_share" target="_blank" class="marginRight"><img src="/assets/images/googleplay-icon.png" class="pull-left" alt="InstaGobalTravel" title="Play Store" width="130"/></a>
<a href="https://apps.apple.com/us/app/instaglobaltravel-apply-visa/id6474402870" target="_blank" class=""><img src="/assets/images/appstore-icon.png" class="pull-left" alt="InstaGobalTravel" title="Apple Store"  width="130"/></a>
<!--<a href="javascript:void(0)" class="borderAll"><img src="/assets/images/igt-app-qrcode.jpg" class="pull-left" alt="InstaGobalTravel" title="qr code" width="130"/></a>-->


</div>


<!--<div class="applink-right">
<img src="/assets/images/barcode.jpg" class="pull-left" alt="InstaGobalTravel" title="QR Code" >
</div>-->

</div>

</div>
</div>

</div>

<div class="row greyBG borderTop">
<div class="wrap black paddingupdwn tabpaddingInBox">
<div class="col-sm-4 container mobRow mobfont11 alignCenter">Copyright © {{currentYear}}. All rights reserved by instaglobaltravel.com</div>

</div>
</div>
</footer>
<!-- footer Panel -->
