import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PagenotfoundComponent} from './modules/front/pagenotfound/pagenotfound.component';

const routes: Routes = [
  {
	path: 'front',
    loadChildren: () => import('./modules/front/front.module').then(m => m.FrontModule)
  },
  {
	path: '',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)
  },
  {
	path: 'customer',
    loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule)
  },
  {
	path: 'corporate',
    loadChildren: () => import('./modules/corporate/corporate.module').then(m => m.CorporateModule)
  },
  {
	path: 'dubai-visa-application-form',
    loadChildren: () => import('./modules/landingform/landingform.module').then(m => m.LandingFormModule)
  },
  {
	path: 'turkey-entry-form',
    loadChildren: () => import('./modules/turkeylandingform/turkeylandingform.module').then(m => m.TurkeyLandingFormModule)
  },
  {
	path: 'turkey-entry-form/:ref_id/:id',
    loadChildren: () => import('./modules/turkeylandingform/turkeylandingform.module').then(m => m.TurkeyLandingFormModule)
  },
  {
	path: 'visa',
    loadChildren: () => import('./modules/visa/visa.module').then(m => m.VisaModule)
  },
  {
	path: 'visa-type',
    loadChildren: () => import('./modules/response/response.module').then(m => m.ResponseModule)
  },
  {
    path: 'dubai-visa',
    loadChildren: () => import('./modules/response/response.module').then(m => m.ResponseModule)
  },
  {
	path: 'sticker-form',
	loadChildren: () => import('./modules/stickerform/stickerform.module').then(m => m.StickerformModule)
  },
  {
    path: ':destination',
    loadChildren: () => import('./modules/response/response.module').then(m => m.ResponseModule)
  },
  {
	path: 'booking-summary/:ref_id',
	loadChildren: () => import('./modules/bookingorder/bookingorder.module').then(m => m.BookingorderModule)
  },
  { path: 'how-:dd-apply',
    loadChildren: () => import('./modules/response/response.module').then(m => m.ResponseModule)
  },
  //Wild Card Route for 404 request
  {
	path: '**', pathMatch: 'full', component: PagenotfoundComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
