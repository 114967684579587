import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { User } from '../_model/user';
import { Country } from '../_model/country';

@Injectable({ providedIn: 'root' })
export class CorporateService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;   

    constructor(
        private http: HttpClient,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.currentUserSubject = new BehaviorSubject<any>(localStorage.getItem('currentUser'));
        this.currentUser = this.currentUserSubject.asObservable();
        
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(email_id: string, password: string) {
        return this.http.post<any>(`${environment.apiUrl}/customer/userLogin`, { email_id, password })
        .pipe(map(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if(user.Code!=204)
            {
                if(user.Data.user_type!='Corporate')
				{
					sessionStorage.setItem('currentUser', JSON.stringify(user));
					localStorage.setItem('currentUser', JSON.stringify(user));
				}else{
					sessionStorage.setItem('corporateUser', JSON.stringify(user));
					
				}
					this.currentUserSubject.next(user);
            }
           
            return user;
        }));
    }

    
    forgotPassword(email_id: string) {
        return this.http.post<any>(`${environment.apiUrl}/customer/forgotPassword`, { email_id })
        .pipe(map(user => {
            return user;
        }));
    }

    resetPassword(password: string, email_id:any) {
        return this.http.post<any>(`${environment.apiUrl}/customer/resetPassword`, { password,email_id})
        .pipe(map(user => {
            this.currentUserSubject.next(user);
            return user;
        }));
    }

    changePassword(current_password: string, password: string, confirm_password: string, id:any) {
        return this.http.post<any>(`${environment.apiUrl}/customer/changePassword`, { current_password, password, confirm_password,id})
        .pipe(map(user => {
            this.currentUserSubject.next(user);
            return user;
        }));
    }

    getDestination(){
        return this.http.get<Country[]>(`${environment.apiUrl}/customer/destination`);
    }
	
	
	

	
	getStaticData(){
        return this.http.get<Country[]>(`${environment.apiUrl}/customer/staticData`);
    }
	
	
	getDestinationName(id:any,destinationId:any) {
        return this.http.post<any>(`${environment.apiUrl}/customer/destinationById`, { id,destinationId })
        .pipe(map(user => {
            this.currentUserSubject.next(user);
            return user;
        }));
    }

   
	
	getDestinationDetails(destination: any, nationality: any, livingin:any) {
       
      return this.http.post<any>(`${environment.apiUrl}/customer/destinationByName`, {destination,nationality,livingin}).pipe(map(getDestinationDetails => {
          return getDestinationDetails;
      }));
    }

    getNationalityLivingIn(id:any) {
        return this.http.get<any>(`${environment.apiUrl}/customer/destination/${id}`).pipe(map(countryData => {
            return countryData;
        }));
    }
      
    

    viewProfile(id:any) {
        return this.http.post<any>(`${environment.apiUrl}/customer/userProfile`, {id}).pipe(map(user => {
            this.currentUserSubject.next(user);
            return user;
        }));
    }
	
	updateProfile(first_name: string, last_name: string, gender: string, passport_no:string, nationality:string,id:any,date_of_birth:string,anniversary_date:string,address:string,city:string,state:string,pincode:string,profilepic:string,passport_expiry_date_day: string,passport_expiry_date_month: string,passport_expiry_date_year: string) {
        return this.http.post<any>(`${environment.apiUrl}/customer/updateProfile`, { first_name, last_name, gender,passport_no,nationality,id,date_of_birth,anniversary_date,address,city,state,pincode,profilepic,passport_expiry_date_day,passport_expiry_date_month,passport_expiry_date_year})
        .pipe(map(user => {
            this.currentUserSubject.next(user);
            return user;
        }));
    }
	
	
	
	

    addEmployee(customer_id:any,first_name: any,last_name:any,gender:any,date_of_birth:any,father_name:any,mother_name:any,nationality:any,livingin:any,address:any,city:any,state:any,pincode:any,passport_no:any,passport_expiry:any,place_of_issue:any,place_of_birth:string,email_id:string,country_isd_code:any,contact_no:any,profile_pic:any,personal_passport_photo:any) {
	
			return this.http.post<any>(`${environment.apiUrl}/corporate/Addemployee`, {customer_id,first_name,last_name,gender,date_of_birth,father_name,mother_name,nationality,livingin,address,city,state,pincode,passport_no,passport_expiry,place_of_issue,place_of_birth,email_id,country_isd_code,contact_no,profile_pic,personal_passport_photo})
        .pipe(map(user => {
            this.currentUserSubject.next(user);
            return user;
        }));
    }
	
	
    updateEmployee(customer_id:any,employee_id:any,first_name: any,last_name:any,gender:any,date_of_birth:any,father_name:any,mother_name:any,nationality:any,livingin:any,address:any,city:any,state:any,pincode:any,passport_no:any,passport_expiry:any,place_of_issue:any,place_of_birth:string,email_id:string,country_isd_code:any,contact_no:any,profile_pic:any,personal_passport_photo:any) {
	
			return this.http.post<any>(`${environment.apiUrl}/corporate/Addemployee`, {customer_id,employee_id,first_name,last_name,gender,date_of_birth,father_name,mother_name,nationality,livingin,address,city,state,pincode,passport_no,passport_expiry,place_of_issue,place_of_birth,email_id,country_isd_code,contact_no,profile_pic,personal_passport_photo})
        .pipe(map(user => {
            this.currentUserSubject.next(user);
            return user;
        }));
    }
	

    allEmployee(corporate_id:any) {
        return this.http.post<[any]>(`${environment.apiUrl}/corporate/getEmpBycustomerId`, {corporate_id})
        .pipe(map(members => {				
            return members;
        }));
    }

    viewEmployeeProfile(corporate_id:any,employee_id:any) {
        return this.http.post<any>(`${environment.apiUrl}/corporate/getEmpBycustomerId`, {corporate_id,employee_id}).pipe(map(user => {
            this.currentUserSubject.next(user);
            return user;
        }));
    }

    updateMemberProfile(first_name: string, email_id: string,last_name: string, passport_no:string, nationality:string,id:any,date_of_birth:string,relationship_id:string,contact_no:string,profilepic:string,country_isd_code:string) {
        return this.http.post<any>(`${environment.apiUrl}/customer/updateMemberProfile`, { first_name, last_name,passport_no,nationality,id,date_of_birth,relationship_id,contact_no,profilepic,country_isd_code})
        .pipe(map(user => {
            this.currentUserSubject.next(user);
            return user;
        }));
    }

    deleteEmployee(employee_id:any) {
        return this.http.post<any>(`${environment.apiUrl}/corporate/deleteEmployee`, {employee_id})
        .pipe(map(user => {
            this.currentUserSubject.next(user);
            return user;
        }));
    }    

    appliedVisa(customer_id:any) {
        return this.http.post<[any]>(`${environment.apiUrl}/customer/appliedVisa`, {customer_id})
        .pipe(map(allApplications => {				
            return allApplications;
        }));
    }

    viewVisaApplication(customer_id:any,visa_id:any) {
        return this.http.post<[any]>(`${environment.apiUrl}/customer/appliedVisa`, {customer_id,visa_id})
        .pipe(map(viewIndividualApplication => {				
            return viewIndividualApplication;
        }));
    }

    customerDashboard(customer_id:any,store_type:any) {
        return this.http.post<[any]>(`${environment.apiUrl}/corporate/dashboard`, {customer_id,store_type})
        .pipe(map(viewCustomerDashboard => {				
            return viewCustomerDashboard;
        }));
    }
	
	getDocumentList(applData: any){
		  return this.http.post<any>(`${environment.apiUrl}/customer/viewDocument`, applData).pipe(map(documentnamelist => {
			return documentnamelist;
      }));
    }

	uploadDocument(applData: any){
          return this.http.post<any>(`${environment.apiUrl}/customer/UploadDocument`, applData).pipe(map(uploadeddocument => {
		  return uploadeddocument;
      }));
    }

    uploadCustomerDocument(applData: any){
        return this.http.post<any>(`${environment.apiUrl}/customer/UploadCustomerDocument`, applData).pipe(map(uploadeddocumentcustomer => {
        return uploadeddocumentcustomer;
    }));
  }
	
		
	
	getCorporateData(customer_id: string) {
        return this.http.post<any>(`${environment.apiUrl}/corporate/getCorporate`, { customer_id })
        .pipe(map(crptuser => {
		//console.log(crptuser);
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if(crptuser.Code!=204)
            {
                return crptuser;
            }
           
        }));
    }
	
	
	getVisaTypesAll(corporate_id: any,destination_id: any,visa_type_id:any){
      return this.http.post<any>(`${environment.apiUrl}/corporate/visaTypeDetails`, {corporate_id,destination_id,visa_type_id}).pipe(map(visatypes => {
		    return visatypes;
      }));
    }
	
	
	getVisaTypes(corporate_id:any,destination_id:any) {
        return this.http.post<any>(`${environment.apiUrl}/corporate/visaType`, { corporate_id,destination_id })
        .pipe(map(visatypes => {
            this.currentUserSubject.next(visatypes);
            return visatypes;
        }));
    }
	
	
	getFamilyVisaTypesAll(customer_id: any,destination_id: any,living_in:any,visa_type_id:any){
      return this.http.post<any>(`${environment.apiUrl}/customer/familyVisaDetails`, {customer_id,destination_id,living_in,visa_type_id}).pipe(map(visatypes => {
		    return visatypes;
      }));
    }
	
	getFamilyVisaTypes(customer_id:any,destination_id:any,living_in:any) {
        return this.http.post<any>(`${environment.apiUrl}/customer/familyVisaTypes`, { customer_id,destination_id,living_in })
        .pipe(map(visatypes => {
            this.currentUserSubject.next(visatypes);
            return visatypes;
        }));
    }
	
	
	 addApplyVisa(corporate_id:any,destination_id: any,visa_type_id:any,travel_date:any,employee_id:any,additional_service_id:any,reference_no:any,travel_id:any) {
	
			return this.http.post<any>(`${environment.apiUrl}/corporate/applyCorporateVisa`, {corporate_id,destination_id,visa_type_id,travel_date,employee_id,additional_service_id,reference_no,travel_id})
        .pipe(map(user => {
            this.currentUserSubject.next(user);
            return user;
        }));
    }
	
	
	addFamilyApplyVisa(customer_id:any,destination_id: any,living_in:any,visa_type_id:any,travel_date:any,employee_id:any,additional_service_id:any,currency_id:any,mission_id:any) {
	
			return this.http.post<any>(`${environment.apiUrl}/customer/applyFamilyVisa`, {customer_id,destination_id,living_in,visa_type_id,travel_date,employee_id,additional_service_id,currency_id,mission_id})
        .pipe(map(user => {
            this.currentUserSubject.next(user);
            return user;
        }));
    }
	
	
	
	 visaOrderSummary(get_data: any){  
      return this.http.post<any>(`${environment.apiUrl}/corporate/summary`, get_data).pipe(map(orderSummary => { 
        return orderSummary;
      }));
    }
	
	
	
	
	 generateOrder(reference_no: any,payment_gateway:any,coupon_applied:any,coupon_code:any,lang:any,corporate_id:any,payment_type,isWalletChecked:any,isPayUsing:any){ 
      return this.http.post<any>(`${environment.apiUrl}/Payment/generateOrder`, {reference_no,payment_gateway,coupon_applied,coupon_code,lang,corporate_id,payment_type,isWalletChecked,isPayUsing}).pipe(map(genOrder => { 
        return genOrder;
      }));
    }
	
    generateOrderStrip(reference_no: any,payment_gateway:any,coupon_applied:any,coupon_code:any,lang:any,tokan:any,corporate_id:any,payment_type,isWalletChecked:any,isPayUsing:any){
      return this.http.post<any>(`${environment.apiUrl}/Payment/generateOrder`, {reference_no,payment_gateway,coupon_applied,coupon_code,lang,tokan,corporate_id,payment_type,isWalletChecked,isPayUsing}).pipe(map(genOrder => {
        return genOrder;
      }));
    }
	generateOrderCCav(reference_no: any,payment_gateway:any,coupon_applied:any,coupon_code:any,lang:any,corporate_id:any,payment_type,isWalletChecked:any,isPayUsing:any){
      return this.http.post<any>(`${environment.apiUrl}/Payment/generateOrder`, {reference_no,payment_gateway,coupon_applied,coupon_code,lang,corporate_id,payment_type,isWalletChecked,isPayUsing}).pipe(map(genOrder => {
        return genOrder;
      }));
    }
    gPayconfirmOrder(reference_no: any,payment_gateway:any,coupon_applied:any,coupon_code:any,lang:any,tokan:any,google_pay_Order:any,corporate_id:any,payment_type,isWalletChecked:any,isPayUsing:any){
      return this.http.post<any>(`${environment.apiUrl}/Payment/gPayconfirmOrder`, {reference_no,payment_gateway,coupon_applied,coupon_code,lang,tokan,google_pay_Order,corporate_id,payment_type,isWalletChecked,isPayUsing}).pipe(map(genOrder => {
        return genOrder;
      }));
    }

    gPayconfirmOrderPaymentLinkExtra(reference_no: any,payment_gateway:any,lang:any,tokan:any,google_pay_Order:any,payment_type:any){
      return this.http.post<any>(`${environment.apiUrl}/Payment/gPayconfirmOrder`, {reference_no,payment_gateway,lang,tokan,google_pay_Order,payment_type}).pipe(map(genOrder => {
        return genOrder;
      }));
    }

    confirmOrder(orderDetails:any){  
      return this.http.post<any>(`${environment.apiUrl}/Payment/confirmOrder`, orderDetails).pipe(map(genOrder => { 
        return genOrder;
      }));
    }

    orderResponse(order_id:any){  
      return this.http.post<any>(`${environment.apiUrl}/Payment/orderResponse`, {order_id}).pipe(map(genOrder => { 
        return genOrder;
      }));
    }

    applyCouponCode(reference_no:any,coupon_code:any,lang:any){  
      return this.http.post<any>(`${environment.apiUrl}/Payment/applyCouponCode`, {reference_no,coupon_code,lang}).pipe(map(genOrder => { return genOrder;
      }));
    }

    paymentCheck(order_id:any,ref:any,lang:any){  
      return this.http.post<any>(`${environment.apiUrl}/Payment/check_payment`, {order_id,ref,lang}).pipe(map(genOrder => { return genOrder;
      }));
    }

    visaOrderSummaryExtra(get_data: any){  
      return this.http.post<any>(`${environment.apiUrl}/Visa/extraPaymentSummary`, get_data).pipe(map(orderSummary => { 
        return orderSummary;
      }));
    }

    generateOrderExtra(reference_no: any,payment_gateway:any,extra_payment_id:any,payment_type:any){ 
      return this.http.post<any>(`${environment.apiUrl}/Payment/generateOrder`, {reference_no,payment_gateway,extra_payment_id,payment_type}).pipe(map(genOrder => { 
        return genOrder;
      }));
    }

    generateOrderExtraStrip(reference_no: any,payment_gateway:any,extra_payment_id:any,payment_type:any,tokan:any){ 
      return this.http.post<any>(`${environment.apiUrl}/Payment/generateOrder`, {reference_no,payment_gateway,extra_payment_id,payment_type,tokan}).pipe(map(genOrder => { 
        return genOrder;
      }));
    }

    

    confirmOrderExtra(orderDetails:any){  
      return this.http.post<any>(`${environment.apiUrl}/Payment/extraConfirmOrder`, orderDetails).pipe(map(genOrder => { 
        return genOrder;
      }));
    }

    orderResponseExtra(order_id:any){  
      return this.http.post<any>(`${environment.apiUrl}/Payment/extraOrderResponse`, {order_id}).pipe(map(genOrder => { 
        return genOrder;
      }));
    }
	
	
	
	visaOrderSummaryPaymentLinkExtra(get_data: any){  
      return this.http.post<any>(`${environment.apiUrl}/Visa/extraPaymentSummaryLink`, get_data).pipe(map(orderSummary => { 
        return orderSummary;
      }));
    }

    generateOrderPaymentLinkExtra(reference_no: any,payment_gateway:any,extra_payment_id:any,payment_type:any){ 
      return this.http.post<any>(`${environment.apiUrl}/Payment/generateOrder`, {reference_no,payment_gateway,extra_payment_id,payment_type}).pipe(map(genOrder => { 
        return genOrder;
      }));
    }
    generateOrderPaymentLinkExtraStrip(reference_no: any,payment_gateway:any,extra_payment_id:any,payment_type:any,tokan:any){
      return this.http.post<any>(`${environment.apiUrl}/Payment/generateOrder`, {reference_no,payment_gateway,extra_payment_id,payment_type,tokan}).pipe(map(genOrder => {
        return genOrder;
      }));
    }

    confirmOrderPaymentLinkExtra(orderDetails:any){  
      return this.http.post<any>(`${environment.apiUrl}/Payment/extraConfirmOrder`, orderDetails).pipe(map(genOrder => { 
        return genOrder;
      }));
    }

    orderResponsePaymentLinkExtra(order_id:any){  
      return this.http.post<any>(`${environment.apiUrl}/Payment/extraOrderResponse`, {order_id}).pipe(map(genOrder => { 
        return genOrder;
      }));
    }
	
	
	visaTypeDetailsByRefNo(corporate_id: any,ref_no:any){
      return this.http.post<any>(`${environment.apiUrl}/corporate/visaTypeDetailsByRefNo`, {corporate_id,ref_no}).pipe(map(visadetailsbyrefno => {
		    return visadetailsbyrefno;
      }));
    }
	
	deleteAppliction(p_details_id: any,ref_no:any){
      return this.http.post<any>(`${environment.apiUrl}/corporate/deleteAppliction`, {p_details_id,ref_no}).pipe(map(deleteappliction => {
		    return deleteappliction;
      }));
    }
	

	
}