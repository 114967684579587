import { Component, OnInit } from '@angular/core';
import { LoaderInterceptorService } from '../../../service/loader-interceptor.service';
import { environment } from '../../../../environments/environment';
import { first } from 'rxjs/operators';
import { CmsPage } from '../../../service/cmspage.service';
declare let $:any;


@Component({
  selector: 'app-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  styleUrls: ['./pagenotfound.component.css']
})
export class PagenotfoundComponent implements OnInit {
  cdnUrl:any;
  page:any;
  env = environment; 
  
  constructor(
    private loaderInterceptorService: LoaderInterceptorService,
    public cmspage: CmsPage,
    ) { }

  ngOnInit(): void {
    if($(window).width() <= 480){ 	
      $(".navigation-panel").hide();
	  $('#footerID').removeClass('mobDisplayNone');
    }
	
	
	//// Start Website Visitor ///////////////////////////////////
	if(sessionStorage.getItem("pathUrl")==null || sessionStorage.getItem("pathUrl")!=window.location.href){
		 this.cmspage.websiteVisitor().pipe(first()).subscribe(page => {});
	  } 
	//// End Website Visitor ///////////////////////////////////   
	

    this.cdnUrl=environment.cdnUrl;
    setTimeout(()=>{this.loaderInterceptorService.hideLoader();},200) 
	
  }

}
