import { Component, OnInit } from '@angular/core';
import { environment } from  '../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { VisaService } from '../../service/visa.service';
import { Observable } from 'rxjs';
declare let $: any;


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
	public summaryComplete : Observable<any>;
	fShow:any;
	env = environment; 
  currentYear:any;
  constructor(
   private router: Router,
   private route: ActivatedRoute,
   private Visaservice: VisaService
  ) { }

ngOnInit(): void {
  this.currentYear = (new Date()).getFullYear();
	this.summaryComplete = this.Visaservice.getMySV(); 
	this.summaryComplete.subscribe((url:any) => {
		this.fShow=url;
	})
  }
}
