export const environment = {
  production: true,
  apiUrl : 'https://api.instaglobaltravel.com',
  baseUrl : 'https://www.instaglobaltravel.com',
  cdnUrl : '',
  pgUrl : 'http://www.instaglobaltravel.com',
  frontPath : 'front/',
  customerPath : 'customer/',
  corporatePath : 'corporate/',
  visaPath : 'visa/',
  domain_id: '1',
  lang: 'en',
  razorpay:{
    store_id:'ID:D87uVI8QaOyTwh',
    key_id:'rzp_live_3loqXf1UwMMyOQ',
    secret_key_id:'ndcwZIXjQhLsEtHRTxI2R9s4',
    //key_id:'rzp_live_KLGpdD0ZYtQhgZ',
    //secret_key_id:'QRHVNHR2SGwx3gj6AQcSCJsK',
    pay_url:''
  },

payPalApiKey:'AXOK2H2p-dSQeXIpeVROKNaWCT8aROu5RrL8VxB_JD15ThdObO5umC4itCY4lexoCn6W_zd9LQJy0eh6',
stripepublishableKeygoogle:'pk_live_51KA8sIHjwckaN1HXce5dAwnQxe9EP8hPis1fTwGdQHbrwLUSZbMuAbRa2fPtn4c4wAItm2vsNj6w6N8iyQiwbhc100NNLwsVMl',
gpaymerchantId:'BCR2DN6TU7IMJ2DC',
merchantName:'Insta Tourism LLC',
environmentgpay: 'PRODUCTION',
robotsMeta: 'index, follow'
};
